import React from "react"
import { Link } from "gatsby"
import Button from "../../../Button"
import {
  Container,
  Box,
  useMediaQuery,
  Typography,
  Grid,
} from "@material-ui/core"
// import Button from "../../../Button"
import { useTheme } from "@material-ui/styles"
import { useStyles } from "./style"
import ReferIllustration from "../../../../images/ReferIllustrationn.svg"

const Refer = props => {
  const classes = useStyles(props)
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"))

  return (
    <Box
      position="relative"
      paddingTop={"5rem"}
      paddingBottom={"4rem"}
      className={classes.root}
    >
      <Container maxWidth={"lg"}>
        <Box padding={""}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box marginTop={isDesktop ? "3rem" : ""} paddingRight={!isMobile ? "7rem" : ""}>
              <Typography variant="h3" gutterBottom>
                Refer a Friend
              </Typography>
              <Typography variant="body1">
                Do you know anyone who needs a loan for education purposes?
                Simply invite them here to save their dreams
              </Typography>
              <Button
                variant="outlined"
                size="large"
                marginTop={"2rem"}
                component={Link}
                to={"/refer"}
                color="primary"
              >
                Refer Now
              </Button>
              </Box>
              
            </Grid>
            <Grid item xs={12} sm={6} >
              <Box className={classes.referIllustration}>
              <img src={ReferIllustration} width={isMobile ? "100%" : "80%"} alt=""/>
              </Box>
              
            
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

Refer.propTypes = {}

export default Refer
// height={"400"} width={!isMobile ? "400" : "100%"}