import React, { useContext, useEffect, useState } from 'react'
import { Container, Box, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import LoanContext from '../../../../context/loan/loanContext';

const Testimonials = props => {
    const [testimonials, setTestimonials] = useState([]);
    const classes = useStyles(props);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { getTestimonials } = useContext(LoanContext)

    useEffect(() => {
        async function loadTestimonials() {
            try {
                const res = await getTestimonials()
                console.log('rrrrrrrrrrrrr', res);
                setTestimonials(res.data.responseData.items)
            } catch (err) {
                console.log(err)
            }
        }
        loadTestimonials()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])


    return (
        <Box id='testimonials' paddingTop={'2rem'} paddingBottom={'7rem'} className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box
                    className={classes.heroContainer}
                >
                    <Box className={classes.partnerImage} align="center" justifyContent={"center"}>
                        <Box style={{ margin: '15px auto' }}>
                            <Typography variant={isMobile ? "caption" : "body1"} style={{ color: '#B9CFF9', fontWeight: '600' }}>
                                TESTIMONIALS
                            </Typography>
                            <Typography variant={isMobile ? "h4" : "h2"} style={{ color: '#FFFFFF' }}>
                                Hear What Our Users Have To Say
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box
                    cdisplay="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop='30px'
                >
                    <Grid container spacing={4} className={classes.cardContainer}>
                        {testimonials.length > 0 && testimonials?.map((testimonial, i) => (
                            <Grid key={i} item xs={12} sm={12} md={3}>
                                <div className={classes.card}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={3} sm={3} md={3}>
                                                <img src={testimonial.pictureUrl} alt="" />
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9}>
                                                <Box
                                                    cdisplay="flex"
                                                    flexDirection="column"
                                                    justifyContent="start"
                                                    alignItems="center"
                                                >
                                                    <Typography style={{ fontWeight: '600', fontSize: '0.85rem' }}>{testimonial.fullname}</Typography>
                                                    <Typography style={{ marginTop: '2px', color: '#828282', fontSize: '0.7rem' }}>{testimonial.occupation}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box
                                        cdisplay="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography style={{ margin: '5px auto', fontSize: '0.7rem' }}>{testimonial.remark}</Typography>
                                    </Box>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

Testimonials.propTypes = {

}

export default Testimonials
