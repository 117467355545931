import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#0E347E',
    },
    view: {
        width: "63%",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        },
    },
    card: {
        background: "#FFFFFF",
        border: "1px solid #EAEEFA",
        borderRadius: "25px",
        padding: '15px'
    }
}))

export { useStyles }