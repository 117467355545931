import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    whoFor: {
        height: '100%',
        backgroundColor: theme.palette.background.default,
        padding: '2rem 2rem 4rem 2rem',
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
    },
}))

export { useStyles }