import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  logos: {
     paddingBottom: "3rem",
      backgroundColor: '#FFFFFF',
  },
  wrapper: {
    padding: "1.5rem",
    // backgroundColor: theme.palette.background.paper,
    borderRadius: "4px",
    height: "100%",
  },
  title: {
    flexGrow: 1,
  },
  container: {
    width: "100%",
    padding: "3rem 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    slider:{
        marginTop: "2rem"
    }
  },
  bar: {
    width: "60%",
    backgroundColor: "inherit",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  tab: {
    textTransform: "capitalize",
  },
  servicesHeader: {
    fontWeight: "800",
    fontSize: "1rem",
    paddingBottom: "1rem",
  },
  partner: {
    //  backgroundColor: "#EDF0F5",
     padding: "2.5rem 0",
     [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0 2rem 0"
    },
  },
  carousel: {
    marginTop: "2rem",
     border: "1px solid red",
     width: "70%",
     [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }
}))

export { useStyles }
