import React, { useState, useEffect, useContext } from "react"
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
} from "@material-ui/core"
import * as Yup from "yup"
import FormErrors from "../../../FormErrors"
import { Formik, Form, ErrorMessage } from "formik"
import CloseIcon from "@material-ui/icons/Close"
import Dialog from "../../../Dialog"
import Button from "../../../Button"
import { useTheme } from "@material-ui/styles"
import { useStyles } from "./style"
import LoanContext from "../../../../context/loan/loanContext"
import AlertContext from "../../../../context/alert/alertContext"

const Feedback = props => {
  const { isLoading, sendFeedback } = useContext(LoanContext)
  const { setAlert } = useContext(AlertContext)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  useEffect(() => {
    setTimeout(function () {
      localStorage.getItem("feedBack") !== "close" &&
        localStorage.setItem("feedBack", "open")
      setOpen(true)
    }, 150000)
  }, [])
  // 180000
  const handleClose = () => {
    setOpen(false)
    localStorage.setItem("feedBack", "close")
  }

  let feedBack
  if (typeof window !== "undefined") {
    feedBack = localStorage.getItem("feedBack")
    window.addEventListener("beforeunload", function (event) {
      localStorage.removeItem("feedBack")
    })
  }

  const theme = useTheme()

  const isMobileOrTab = useMediaQuery(theme.breakpoints.down("sm"))
  const optionA = "I am not ready yet"
  const optionB = "I have applied already"
  const optionC = "I don’t know how to proceed. I need more information"
  const optionD = "I don’t have the required documentation"

  const options = [optionA, optionB, optionC, optionD]

  const classes = useStyles()
  let initialValues = {
    message: message,
    email: "",
    phoneNo: "",
  }

  const onSubmit = values => {
    // if (values.email === "" && values.phoneNo === "") {
    //   return setAlert(
    //     "Please provide either your email or Phone Number",
    //     "error"
    //   )
    // }
    sendFeedback(values)
    setAlert("Thank you for your feedback", "success")
    handleClose()
  }

  const validationSchema = Yup.object({
    message: Yup.string().required("Feedback is empty"),
    phoneNo: Yup.string()
      .min(9, "Phone Number must not be less that 9 digits")
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        "Enter a valid phone number"
      ),
    email: Yup.string().required("Email is required").email("Invalid email format!"),
  })

  const content = (
    <Box className={classes.container}>
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ padding: "16px 24px 0 24px" }}
      >
        <div className={classes.header}>
          <Typography className={classes.title}>
            Your Opinion Matters, Please Share Your Feedback
          </Typography>{" "}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        {message === "" && (
          <Typography gutterBottom className={classes.text}>
            Help us provide the best experience for you
          </Typography>
        )}
        <Box>
          <Box display={message === "" ? "block" : "none"}>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label=""
                name="message"
                value={message}
                onChange={e => {
                  setMessage(e.target.value)
                  // console.log(message)
                }}
              >
                {options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={
                      <Radio
                        color="default"
                        size="small"
                        className={
                          message === option
                            ? classes.radioActiveIcon
                            : classes.radioIcon
                        }
                      />
                    }
                    label={
                      <Typography variant="caption" className={classes.text}>
                        {option}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
              <Box className={classes.compact}></Box>
            </FormControl>
          </Box>

          {/* MAIN FORM */}
          <Box display={message !== "" ? "block" : "none"}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ values, getFieldProps }) => (
                <Form noValidate autoComplete="off" className={classes.form}>
                  {/* COMENTS */}
                  <Box
                    marginTop={2}
                    display={message !== "" ? "block" : "none"}
                  >
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.text}>Feedback</Typography>
                      <TextField
                        name="message"
                        id="message"
                        fullWidth
                        multiline
                        placeholder="Enter message here"
                        rows={4}
                        {...getFieldProps("message")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="message" component={FormErrors} />
                    </FormControl>
                    <Grid
                      container
                      spacing={isMobileOrTab ? 1 : 3}
                      style={{ marginTop: "0.5rem" }}
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Typography className={classes.text}>
                            Email
                          </Typography>
                          <TextField
                            name="email"
                            id="email"
                            {...getFieldProps("email")}
                            variant="outlined"
                            size="small"
                          />
                          <ErrorMessage name="email" component={FormErrors} />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Typography className={classes.text}>
                            Phone Number (optional)
                          </Typography>
                          <TextField
                            name="phoneNo"
                            id="phoneNo"
                            {...getFieldProps("phoneNo")}
                            variant="outlined"
                            size="small"
                          />
                          <ErrorMessage
                            name="phoneNo"
                            component={FormErrors}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* COMENTS */}

                  <Box marginTop={2}>
                    <DialogActions>
                      <Typography
                        style={{ marginRight: "1rem", cursor: "pointer" }}
                        className={classes.text}
                        color="primary"
                        onClick={() => {
                          setMessage("")
                          values.message = ""
                        }}
                      >
                        Back
                      </Typography>

                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                        color="primary"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  )

  return (
    <div>
      <Dialog
        handleClose={handleClose}
        open={open && feedBack === "open"}
        content={content}
      />
    </div>
  )
}

export default Feedback
