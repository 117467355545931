import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    cascadingWrapper: {
        padding: '1.5rem 0 1.5rem 10rem'
    },
    cascadingImages: {
        position: 'relative',
        height: '100%',
        // padding: '',
        '& .cascading-image' : {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: '4px',
            position: 'absolute',
        },
        '& .cascading-image:nth-child(1)' : {
            right: '0',
            top: '0',
            width: '18rem',
            height: '15rem',
            zIndex: 3,
        },
        '& .cascading-image:nth-child(2)' : {
            left: '0',
            top: '20%',
            width: '23rem',
            height: '19rem',
            zIndex: 2,
        },
        '& .cascading-image:nth-child(3)' : {
            right: '0',
            bottom: '0',
            width: '18rem',
            height: '15rem',
            zIndex: 1,
        }
    },
}))

export { useStyles }