import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  header: {
    width: "100%",
    borderBottom: "1px solid #D7DCE0",
    paddingBottom: "0.5rem",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#000000",
    fontWeight: "600",
    fontSize: "0.4rem"
  },
  title: {
    color: "#000000",
    fontWeight: "600",
    width: "90%",
    fontSize: "15px"
  },
 text: {
     fontSize: "14px"
 },
compact: {
    position: "relative",
    top: "-0.5rem"
},
formControl: {
    width: "100%"
},
radioIcon: {
    color: theme.palette.text.main,
    '&:checked': {
        color: theme.palette.text.main,
    },
},
radioActiveIcon: {
    color: `${theme.palette.primary.main} !important`,
    '&:checked': {
        color: `${theme.palette.primary.main} !important`,
    },
},
}))

export { useStyles }
