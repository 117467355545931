import React, { useContext, useEffect } from 'react'
import Hero from './Hero'
import About from './About'
import Video from './Video'
import Why from './Why'
import WhoFor from './WhoFor'
import Steps from './Steps'
import Services from './Services'
import Loans from './Loans'
import Testimonials from './Testimonials'
import Faq from './Faq'
import Refer from './Refer'
import data from '../Data'
import Feedback from './Feedback'
import loanContext from '../../../context/loan/loanContext'
import PartnerWithUs from './PartnerWithUs'

const HomeLayout = props => {
     const {
          hero,
          about,
          video,
          why,
          whoFor,
          steps,
          services,
          loans,
          testimonials,
     } = data
     const {
          faq,
          getFaqs,
     } = useContext(loanContext)

     useEffect(() => {
          faq.length < 1 && getFaqs()
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

     return (
          <>
               <Hero
                    data={hero}
               />

               <About
                    data={about}
               />

               {/* <Video
                    data={video}
               /> */}

               <Services
                    data={services}
               />

               <PartnerWithUs />

               <Why
                    data={why}
               />

               {/* <WhoFor
                data={whoFor}
               /> */}

               {/* <Steps
                data={steps}
               /> */}

               {/* <Loans
                data={loans}
               /> */}

               {/* <Refer /> */}

               {/* <Faq
               title="Frequently Asked Questions"
               QandA={faq.slice(0, 3)}
               /> */}

               <Testimonials
                    data={testimonials}
               />

               <Feedback />
          </>
     )
}

HomeLayout.propTypes = {

}

export default HomeLayout
