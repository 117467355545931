import { makeStyles } from '@material-ui/core/styles'
import line from "../../../../images/line.svg"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    // imageWrapper: {
    //     width: '100%',
    //     maxWidth: '38rem',
    //     height: '35rem',
    //     backgroundImage : props => `url(${props.data.imageUrl})`,
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     borderRadius: '4px',
    //     marginLeft: '6rem',
    //     [theme.breakpoints.down('sm')]: {
    //         maxWidth: '100%',
    //         height: '28rem',
    //         marginLeft: 0
    //     },
    // },
    action: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'space-around'
        },

    },
    partner: {
        marginLeft: "1rem",
        // [theme.breakpoints.down("xs")]: {
        //     marginTop: "1rem",
        //     marginLeft: "0"
        //   },
    },
    introContainer: {
        // height: "13rem",
        width: "85%",
        paddingTop: '6rem',
        // border: "2px solid green",
        // color: "#ffffff",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
        },
        "& button": {
            width: '200px'
        }
    },
    line: {
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        paddingBottom: '13px',
        backgroundSize: "inherit",
        backgroundImage: `url(${line})`

    },
    heroBox: {
        margin: "3rem auto 1rem",
        // border: "2px solid green",
        display: "flex",
        // flexDirection: "row",
        // justifyContent: "center",
        // width: "80%",
        // [theme.breakpoints.down("sm")]: {
        //     width: "90%",
        // },
        // [theme.breakpoints.down("xs")]: {
        //     display: "block",
        // },
    },
    heroes: {
        marginTop: '.5rem',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
}))

export { useStyles }