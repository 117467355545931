import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {},
    imageWrapper: {
        width: '100%',
        // maxWidth: '38rem',
        height: '30rem',
        backgroundImage : props => `url(${props.data.imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'rgba(4, 1, 16, .7)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            height: '20rem',
        },
    },
}))

export { useStyles }