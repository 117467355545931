import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth : 600,
        outline : 0,
    },
    embedContainer: {
        position: 'relative',
        paddingBottom: '56.25%',
        height: '0',
        overflow: 'hidden',
        maxWidth: '100%', 
    },
}));

export { useStyles }