import React, { useContext } from 'react'
import { Container, Box, Typography, Grid, Hidden } from '@material-ui/core'
import Button from '../../../Button'
import { useStyles } from './style'

import AuthContext from "../../../../context/auth/authContext"

const Steps = props => {
    const { title, body, images, steps } = props.data
    const classes = useStyles(props)

    const { isAuthenticated } = useContext(AuthContext)
    const link = isAuthenticated ? "/dashboard" : "/user/register"

    return (
        <Box id='steps' position='relative' paddingTop={'5rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Typography variant="h2" align="center" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body1" align="center">
                    {body}
                </Typography>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={5}>
                            <Box marginTop='6rem'>
                                {steps.map((step, key) => (
                                    <Box key={key} className={classes.step} marginBottom="3.5rem">
                                        <Typography variant="h4" color="primary" gutterBottom>
                                            {step.title}
                                        </Typography>
                                        <Typography variant="body1" >
                                            {step.body}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7}>
                            <Hidden smDown>
                                <Box height="100%" className={classes.cascadingWrapper}>
                                    <Box className={classes.cascadingImages}>
                                        {images.map((image, key) => (
                                            <Box key={key} height="20px" className="cascading-image" style={{ backgroundImage: `url(${image})` }}>
                                                
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Hidden>
                        </Grid>
                        
                    </Grid>
                    <Button variant="contained" size="large" link to={link} color="primary">
                        Apply Now
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}

Steps.propTypes = {

}

export default Steps
