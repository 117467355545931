import React, { useContext } from 'react'
import { Container, Box, useMediaQuery, Typography, Grid } from '@material-ui/core'
import Button from '../../../Button'
import { useTheme } from '@material-ui/styles'
import { useStyles } from './style'
import AuthContext from '../../../../context/auth/authContext'
import { Link } from 'gatsby'


const About = props => {
    const cardDetails = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { isAuthenticated } = useContext(AuthContext)
    const link = isAuthenticated ? '/dashboard' : '/user/register'


    return (
        <Box id='about' position='relative' paddingTop={'2rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box
                    className={classes.heroContainer}
                >
                    <Box className={classes.partnerImage} align="center" justifyContent={"center"}>
                        <Box style={{ margin: '15px auto' }}>
                            <Typography variant={isMobile ? "caption" : "body1"} style={{ color: '#2C6EEC', fontWeight: '600' }}>
                                WHO BURSERY IS FOR
                            </Typography>
                            <Typography variant={isMobile ? "h4" : "h2"}>
                                We Have Partnered With A Wide Range Of {!isMobile && <br />}
                                Institutions & Corporate Organisations To {!isMobile && <br />}
                                offer Loans To Students, Parents &{!isMobile && <br />} Employees
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    cdisplay="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container spacing={4} className={classes.cardContainer}>
                        {cardDetails.map((card, i) => (
                            <Grid key={i} item xs={12} sm={12} md={4}>
                                <div className={classes.card} style={{ height: isMobile ? '450px' : '490px' }}>
                                    <Box display="flex" alignItems="start">
                                        <img src={card.image} width="100%" alt="" />
                                    </Box>
                                    <Typography variant="h4" style={{ marginTop: '15px' }}>{card.title}</Typography>
                                    <Typography variant={isMobile ? "caption" : "body1"} style={{ marginTop: '15px' }}>{card.content}</Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        marginTop={isMobile ? '1rem' : "1.5rem"}
                                        marginBottom={"1rem"}
                                        className={classes.button}
                                        textAlign="center"
                                        position="absolute"
                                        component={Link}
                                        to={link}
                                    >
                                        {card.action}
                                    </Button>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

About.propTypes = {

}

export default About
