import React from 'react'
import { Container, Box, Typography, useMediaQuery, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import coinImage from '../../../../images/coinImage.png'

const Services = props => {
    const { reasons } = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));


    return (
        <Box id='services' position='relative' paddingTop={'2rem'} paddingBottom={'3rem'} className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box
                    className={classes.heroContainer}
                >
                    <Box className={classes.partnerImage} align="center" justifyContent={"center"}>
                        <Box style={{ margin: '15px auto' }}>
                            <Typography variant={isMobile ? "caption" : "body1"} style={{ color: '#2C6EEC', fontWeight: '600' }}>
                                WHY BURSERY?
                            </Typography>
                            <Typography variant={isMobile ? "h4" : "h2"}>
                                Secure Collateral-Free Educational{!isMobile && <br />} Loans Easily
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    cdisplay="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ margin: '50px auto' }}
                >
                    <Container maxWidth={'lg'}>
                        <Grid container spacing={4}>

                            <Grid xs={12} sm={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {reasons.map((reason, i) => (
                                    <div style={{ maxWidth: '430px' }}>
                                        <Typography variant="h4" fontWeight="600" style={{ marginBottom: '10px' }}>
                                            {reason.title}
                                        </Typography>
                                        <Typography variant="body1" fontWeight="600" style={{ marginBottom: '30px' }}>
                                            {reason.details}
                                        </Typography>
                                    </div>
                                ))}
                            </Grid>
                            <Grid xs={12} sm={12} md={6} style={{ display: isMobile ? 'none' : 'block' }}>
                                <img src={coinImage} alt='coinImage' height='auto' width='100%' />
                            </Grid>
                        </Grid></Container>
                </Box>
            </Container>
        </Box>
    )
}

Services.propTypes = {

}

export default Services
