import React from "react"
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import HomeLayout from "../components/Layout/HomeLayout"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout withFooter withHeader>
        <Box>
          <HomeLayout />
        </Box>
    </Layout>
  </>
)

export default IndexPage


