import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    whys: {
        marginTop: '1.5rem',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    why: {
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
       
    },
    space: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '2.5rem',
        },
    },
    iconWrapper: {
        width: '8.5rem',
        height: '8.5rem',
        backgroundColor: '#F2F8FF',
        borderRadius: '50%',
        marginBottom: '1.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '3.5rem',
            height: '3.5rem',
        },
    },
    cardContainer: {
        
    }
}))

export { useStyles }