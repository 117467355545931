import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#EFF2F6"
    },
    services: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2rem",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "space-around",
        },
    },
    serviceIcon: {
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "3rem",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main,
    },
    serviceTitle: {
        marginTop: "0.5rem",
        fontWeight: "600"
    },
    vl: {
        borderLeft: '1px solid #9DA9B3',
        height: '100%',
        position: 'absolute',
        left: '50%',
        marginLeft: '-3px',
        top: 0
    }

}))

export { useStyles }