import React, { useContext } from 'react'
import { Container, Box, useMediaQuery, Typography, Grid, Hidden } from '@material-ui/core'
import Button from '../../../Button'
import { Link } from "gatsby"
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import AuthContext from '../../../../context/auth/authContext'


const Hero = (props) => {
    const heroImages = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { isAuthenticated } = useContext(AuthContext)
    const link = isAuthenticated ? '/dashboard' : '/user/register'

    return (
        <Box id='hero' position='relative' paddingTop='5rem' paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box
                    className={classes.heroContainer}
                >
                    <Box className={classes.partnerImage} align="center" justifyContent={"center"}>
                        <Box className={classes.introContainer}>
                            <Typography variant={isMobile ? "h3" : "h1"} gutterBottom>
                                Quick Access to <span className={classes.line} style={{ color: '#2C6EEC' }}>Affordable</span> Education {!isMobile && <br />}
                                Finance & Services
                            </Typography>
                            <Typography variant={isMobile ? "caption" : "body1"}>
                                We empower people to make a difference in lives of students across the globe by providing easy
                                and  {!isMobile && <br />} instant access to educational loans and other supportive services{" "}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                marginTop={isMobile ? '1rem' : "2rem"}
                                marginBottom={"1rem"}
                                className={classes.button}
                                textAlign="center"
                                component={Link}
                                to={link}
                            >
                                Apply For A Loan
                            </Button>
                        </Box>
                    </Box>

                </Box>
                <Box className={classes.heroBox}
                    cdisplay="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container spacing={isMobile ? 2 : 4} className={classes.heroes}>
                        {heroImages.map((hero, i) => (
                            <Grid key={i} item xs={6} sm={6} md={3} style={{ margin: isMobile ? '20px auto' : '10px auto' }}>
                                <div className={classes.herox}>
                                    <img src={hero} width="100%" alt="" style={{ marginTop: i % 2 === 0 ? '-40px' : 'auto', marginBottom: i % 2 !== 0 ? '-40px' : 'auto' }} />
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container >
        </Box >
    )
}

Hero.propTypes = {

}

export default Hero
