import React, { useContext } from 'react'
import { Container, Box, Grid, Button, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import Grant from "../../../../images/grantIcon.svg"
import Scholarship from "../../../../images/scholarshipIcon.svg"
import AuthContext from '../../../../context/auth/authContext'
import { Link } from 'gatsby';


const Services = props => {
    // const { serviceTitle, body, services } = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { isAuthenticated } = useContext(AuthContext)
    const link = isAuthenticated ? '/dashboard' : '/user/register'

    return (
        <Box id='services' position='relative' paddingTop={'2rem'} paddingBottom={'3rem'} className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box
                    className={classes.heroContainer}
                >
                    <Box className={classes.partnerImage} align="center" justifyContent={"center"}>
                        <Box style={{ margin: '15px auto' }}>
                            <Typography variant={isMobile ? "caption" : "body1"} style={{ color: '#2C6EEC', fontWeight: '600' }}>
                                MORE THAN JUST LOANS
                            </Typography>
                            <Typography variant={isMobile ? "h4" : "h2"}>
                                Asides loans, we also offer grants & {!isMobile && <br />}
                                scholarships that helps remove any barrier{!isMobile && <br />}
                                on your quest to quality education
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box
                    className={classes.heroContainer}
                    style={{ margin: "3rem auto 1rem" }}
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        position="relative"
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={5} md={5}>
                                <Box align="center" justifyContent={"center"}>
                                    <Box style={{ margin: '15px auto' }}>
                                        <Box>
                                            <img src={Grant} alt="Grant Icon" />
                                        </Box>
                                        <Typography variant={"h3"} style={{ fontWeight: '600' }}>
                                            Grants
                                        </Typography>
                                        <Typography variant={isMobile ? "caption" : "body1"} style={{ margin: '1rem auto' }}>
                                            Do you require funds for academic or{!isMobile && <br />}
                                            administrative projects? You are only a{!isMobile && <br />}
                                            few steps away.
                                        </Typography>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                marginTop={isMobile ? '1rem' : "2rem"}
                                                marginBottom={"1rem"}
                                                className={classes.button}
                                                textAlign="center"
                                                style={{ textTransform: 'inherit', margin: '1rem auto', background: "#81909D", color: "#24292E", boxShadow: "none" }}
                                                component={Link}
                                                to={link}
                                            >
                                                Coming Soon
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={0} sm={0} md={2} style={{ display: isMobile ? 'none' : 'block' }}>
                                <div class={classes.vl}></div>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <Box align="center" justifyContent={"center"}>
                                    <Box style={{ margin: '15px auto' }}>
                                        <Box>
                                            <img src={Scholarship} alt="Scholarship Icon" />
                                        </Box>
                                        <Typography variant={"h3"} style={{ fontWeight: '600' }}>
                                            Scholarships
                                        </Typography>
                                        <Typography variant={isMobile ? "caption" : "body1"} style={{ margin: '1rem auto' }}>
                                            Your dream of receiving a high-quality{!isMobile && <br />}
                                            education should not be dashed due to a{!isMobile && <br />}
                                            lack of funds. Make it a reality right now.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            marginTop={isMobile ? '1rem' : "2rem"}
                                            marginBottom={"1rem"}
                                            className={classes.button}
                                            textAlign="center"
                                            style={{ textTransform: 'inherit', margin: '1rem auto', background: "#81909D", color: "#24292E", boxShadow: "none" }}
                                            component={Link}
                                            to={link}
                                        >
                                            Coming Soon
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Box>

                </Box>

            </Container>
        </Box>
    )
}

Services.propTypes = {

}

export default Services
