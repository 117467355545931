import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
      backgroundColor: "#ffffff",
      
  },
  refer: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
      width: "70%",
      backgroundColor:  theme.palette.primary.main,
      borderRadius: "4px",
      color:"white",
      padding: theme.spacing(2.5, 3),
      [theme.breakpoints.down("xs")]: {
        width: "100%"
      },
  },
  button: {
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      padding: "8px 24px",
      color: theme.palette.primary.main,
      fontWeight: "600",
      border: "none",
      cursor: "pointer",
      outline: "none"
  },
referIllustration: {
  // border: "2px solid green",
  //  height: "12rem",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "12rem",
      marginTop: "1rem"
    },
}

}))

export { useStyles }