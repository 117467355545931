import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    partnerContainer: {
        backgroundColor: '#2C6EEC',
        color: '#FFFFFF'
    },
    // imageWrapper: {
    //     width: '100%',
    //     maxWidth: '38rem',
    //     height: '35rem',
    //     backgroundImage : props => `url(${props.data.imageUrl})`,
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'center',
    //     borderRadius: '4px',
    //     marginLeft: '6rem',
    //     [theme.breakpoints.down('sm')]: {
    //         maxWidth: '100%',
    //         height: '28rem',
    //         marginLeft: 0
    //     },
    // },
    action: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'space-around'
        },

    },
    partner: {
        marginLeft: "1rem",
        // [theme.breakpoints.down("xs")]: {
        //     marginTop: "1rem",
        //     marginLeft: "0"
        //   },
    },
    introContainer: {
        // height: "13rem",
        width: "85%",
        paddingTop: '3rem',
        paddingBottom: '3rem',
        // border: "2px solid green",
        // color: "#ffffff",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
        },
        "& a": {
            width: '200px',
            color: '#1354D3',
            backgroundColor: '#FFFFFF !important'
        }
    },
    heroBox: {
        margin: "1rem auto",
        // border: "2px solid green",
        display: "flex",
        // flexDirection: "row",
        // justifyContent: "center",
        // width: "80%",
        // [theme.breakpoints.down("sm")]: {
        //     width: "90%",
        // },
        // [theme.breakpoints.down("xs")]: {
        //     display: "block",
        // },
    },
    heroes: {
        marginTop: '.5rem',
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    hero: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}))

export { useStyles }