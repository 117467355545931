import React, { useContext } from 'react'
import { Container, Box, useMediaQuery, Typography, Grid, Hidden } from '@material-ui/core'
import { Link } from "gatsby"
import Button from '../../../Button'
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import AuthContext from '../../../../context/auth/authContext'


const PartnerWithUs = (props) => {
    // const heroImages = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const { isAuthenticated } = useContext(AuthContext)
    const link = isAuthenticated ? '/dashboard' : '/user/register'

    return (
        <Box id='hero' position='relative' paddingTop='3.5rem' paddingBottom="3rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box
                    className={classes.heroContainer}
                >
                    <Box className={classes.partnerContainer} align="center" justifyContent={"center"}>
                        <Box className={classes.introContainer}>
                            <Typography variant={isMobile ? "h3" : "h1"} gutterBottom>
                                Partner With Us
                            </Typography>
                            <Typography variant={isMobile ? "caption" : "body1"}>
                                We are constantly looking for forward thinking {!isMobile && <br />}
                                educational institutions and cooperate organisations to{!isMobile && <br />}
                                partner with us and provide our users the financial{!isMobile && <br />}
                                freedom they need
                            </Typography>
                            <Button
                                variant="contained"
                                marginTop={isMobile ? '1rem' : "2rem"}
                                marginBottom={"1rem"}
                                className={classes.button}
                                textAlign="center"
                                style={{ textTransform: 'inherit' }}
                                component={Link}
                                to={link}
                            >
                                Partner With Us
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container >
        </Box >
    )
}

PartnerWithUs.propTypes = {

}

export default PartnerWithUs
